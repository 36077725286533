
import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../../config";
import CommonDeleteModal from "../../../common-components/CommonDeleteModal.vue";
import UpdateEmailAndPhone from "../../../common-components/UpdateEmailAndPhone.vue";
import CommonUserChoiceModal from "../../../common-components/CommonUserChoiceModal.vue";
import Axios from "axios";
import { authHeader } from "../../../services/auth";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import Multiselect from "vue-multiselect";
import datepicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";

@Component({
  components: {
    CommonDeleteModal,
    CommonUserChoiceModal,
    UpdateEmailAndPhone,
    PerfectScrollbar,
    datepicker,
    Multiselect,
  },
})
export default class ManageBroker extends Vue {
  /* eslint-disable */
  public loanList = [];
  public skip = 0;
  public totalLength = 0;
  public currentLengthOnScroll = 0;
  public logData = [];
  public showLoader = false;
  public lockDateStatus = false;
  public closingDateStatus = false;
  public sortType = null;
  public sortParam = null;
  public showHide = false;
  public updatedSubmissionDate = null;
  public loanStatus = null;
  public loanId: any = null;
  public error = false;
  public lockExp: any = {
    status: "",
    note: "",
  };
  public closingDate: any = {
    status: "",
    note: "",
  };
  public sentToProcessingRemark: any = null;
  public remarks: any = "";
  public status: number = 0;
  public statusParam: any = "inProcess";
  public searchParam = "";
  public searchKeyword = "";
  public allProcessor: any = [];
  public selectedProcesor: any = null;
  public fieldName: any = "";
  public sortingType: any = 1;
  public currentDate = null;
  public isSameDate = false;
  public msg = "";
  //  public msg ="No Log Activities";

  public async openChangeProcessorModal(loanId) {
    try {
      this.loanId = loanId;
      await this.getSeniorrocessorList();
      this.$modal.show("changeProcessorModal");
    } catch (error) {
      console.log(error);
    }
  }

  public async closeChangeProcessorModal() {
    try {
      this.selectedProcesor = null;
      this.loanId = null;
      this.allProcessor = [];
      this.$modal.hide("changeProcessorModal");
    } catch (error) {
      console.log(error);
    }
  }

  public async changeProessorOfLOan() {
    try {
      const response = await Axios.post(
        `${BASE_API_URL}super-admin/loans/changeProessorOfLOan`,
        { loanId: this.loanId, selectedProcesor: this.selectedProcesor },
        { headers: authHeader() }
      );
      this.$snotify.success("Senior processor changed successfully.");
      await this.closeChangeProcessorModal();
    } catch (error) {
      console.log(error);
    }
  }

  public async getSeniorrocessorList() {
    try {
      const response = await Axios.post(
        `${BASE_API_URL}super-admin/loans/getSeniorrocessorList`,
        { loanId: this.loanId },
        { headers: authHeader() }
      );
      this.allProcessor = response.data;
    } catch (error) {
      console.log(error);
    }
  }
  public async getAllLoanList(mountCall) {
    mountCall
      ? (this.$store.state.tmpcoLoader = true)
      : (this.showLoader = true);
    try {
      this.skip = mountCall ? 0 : this.skip + 15;
      const response = await Axios.get(
        `${BASE_API_URL}super-admin/loans/getAllLoanList`,
        {
          params: {
            options: {
              skip: this.skip,
              limit: 15,
            },
            statusParam: this.statusParam,
            searchParam: this.searchParam.trim(),
            searchKeyword: this.searchKeyword.trim(),
            sortType: this.sortType,
            sortParam: this.sortParam,
          },

          headers: authHeader(),
        }
      );
      // console.log(response.data);

      if (response.status === 201) {
        if (mountCall) {
          this.loanList = response.data.promise ? response.data.promise : [];
        } else {
          const data = response.data.promise ? response.data.promise : [];
          this.loanList = this.loanList.concat(data);
        }
        this.sortAccordingly(this.fieldName, this.sortingType);

        this.totalLength = response.data.loanDataLength;
        this.currentLengthOnScroll = this.loanList.length;
      }
    } catch (error) {
      console.log(error);
    }
    mountCall
      ? (this.$store.state.tmpcoLoader = false)
      : (this.showLoader = false);
  }

  public async deleteLoan(selectedLoan) {
    try {
      await this.$refs.ref["delConfirmationBox"]({
        msg: `Are you sure want to delete this ${selectedLoan.loanId} Loan ?`,
        data: { loanId: selectedLoan.loanId },
        apiPath: "super-admin/loans/deleteLoan",
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async updateEmailAndPhone(selectedLoan, type) {
    try {
      const data =
        type === "Phone"
          ? { phone: selectedLoan.primaryBorrower.phone }
          : { email: selectedLoan.primaryBorrower.email };
      await this.$refs.REF["updateData"]({
        ...data,
        userId: selectedLoan.userId,
        loanId: selectedLoan.loanId,
      });
    } catch (error) {
      console.log(error);
    }
  }

  public goTo(loanId) {
    this.$router.push({ path: "/workflow", query: { id: loanId } });
  }

  public addLockExp(data, lockdata) {
    this.loanId = data;
    this.lockExp = {
      status: "",
      note: "",
    };
    this.$modal.show("lockExp");
  }

  public addclosingDate(data, closeingdata) {
    this.loanId = data;
    this.closingDate = {
      status: "",
      note: "",
    };
    this.$modal.show("closeingdate");
  }

  public async sendVerificationLinkToBorrower(data) {
    this.$store.state.tmpcoLoader = true;
    try {
      const forServer = {
        email: data.primaryBorrower.email,
        loanId: data.loanId,
        userId: data.userId,
        fullName: data.primaryBorrower.name,
      };
      const response = await Axios.post(
        `${BASE_API_URL}super-admin/loans/sendVerificationLinkToBorrower`,
        forServer,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.$snotify.success(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
  }

  public async scrollHandle(evt) {
    let scroll: any = this.$refs.scrollbar;
    if (
      !this.showLoader &&
      scroll.ps.reach.y == "end" &&
      // x.scrollTop + x.clientHeight <= x.scrollHeight &&
      this.currentLengthOnScroll < this.totalLength
    ) {
      await this.getAllLoanList(false);
    }
  }

  public async getLogData(data) {
    try {
      const processordata = {
        loanId: data.loanId,
      };
      const response = await Axios.post(
        `${BASE_API_URL}super-admin/loans/getLogData`,
        processordata,
        { headers: authHeader() }
      );

      if (response.status === 201) {
        this.logData = response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public sortAccordingly(fieldName, type) {
    if (type == 1) {
      this.loanList.sort((a: any, b: any) => {
        let fa: any = null;
        let fb: any = null;

        if (fieldName == "LoanType") {
          (fa = a.loanPurpose.name.toLowerCase()),
            (fb = b.loanPurpose.name.toLowerCase());
        } else if (fieldName == "BrokerCompanyName") {
          (fa = a.brokerCompanyName.toLowerCase()),
            (fb = b.brokerCompanyName.toLowerCase());
        } else if (fieldName == "BrokerName") {
          (fa = a.broker.firstName.toLowerCase()),
            (fb = b.broker.firstName.toLowerCase());
        } else if (fieldName == "status") {
          (fa = a.mileStone ? a.mileStone.toLowerCase() : ""),
            (fb = b.mileStone ? b.mileStone.toLowerCase() : "");
        }

        if (fb > fa) {
          return -1;
        }
        return 0;
      });
    } else if (type == -1) {
      this.loanList.sort((a: any, b: any) => {
        let fa: any = null;
        let fb: any = null;
        if (fieldName == "LoanType") {
          (fa = a.loanPurpose.name.toLowerCase()),
            (fb = b.loanPurpose.name.toLowerCase());
        } else if (fieldName == "BrokerCompanyName") {
          (fa = a.brokerCompanyName.toLowerCase()),
            (fb = b.brokerCompanyName.toLowerCase());
        } else if (fieldName == "BrokerName") {
          (fa = a.broker.firstName.toLowerCase()),
            (fb = b.broker.firstName.toLowerCase());
        } else if (fieldName == "status") {
          (fa = a.mileStone ? a.mileStone.toLowerCase() : ""),
            (fb = b.mileStone ? b.mileStone.toLowerCase() : "");
        }

        if (fb < fa) {
          return -1;
        }
        return 0;
      });
    }
  }

  public async exportXmlFile(loanId) {
    try {
      const response = await Axios.post(
        BASE_API_URL + "broker/exportXmlFile",
        { loanId: loanId },
        {
          headers: authHeader(),
        }
      );
      if (response.data.xmlData) {
        const dom = document.createElement("a");
        const filename = loanId + ".xml";
        const blob = new Blob([response.data.xmlData], { type: "text/plain" });
        dom.setAttribute("href", window.URL.createObjectURL(blob));
        dom.setAttribute("download", filename);
        dom.dataset.downloadurl = ["text/plain", dom.download, dom.href].join(
          ":"
        );
        dom.draggable = true;
        dom.classList.add("dragout");
        dom.click();
      }
    } catch (error) {
      console.log(error);
    }
  }

  public checkDate() {
    if (
      moment(this.updatedSubmissionDate).format("MM/DD/YYYY") ==
      moment(this.currentDate).format("MM/DD/YYYY")
    ) {
      this.isSameDate = true;
      this.msg = "Please Select Different Date";
    } else {
      this.isSameDate = false;
    }
  }

  public async updateSubmissionDate() {
    try {
      if (
        moment(this.updatedSubmissionDate).format("MM/DD/YYYY") ==
        moment(this.currentDate).format("MM/DD/YYYY")
      ) {
        this.isSameDate = true;
        this.msg = "Please Select Different Date";
        return;
      } else if (this.updatedSubmissionDate == null) {
        this.isSameDate = true;
        this.msg = "Please enter the Sent to Processing Date ";
        return;
      }
      let res = await Axios.post(
        BASE_API_URL + "processor/dashboard/updateSubmissionDate",
        {
          loanId: this.loanId,
          submissionDate: this.updatedSubmissionDate,
          sentToProcessingRemark: this.sentToProcessingRemark,
        },
        {
          headers: authHeader(),
        }
      );
      if (res) {
        this.sentToProcessingRemark = null;
        this.getAllLoanList(true);
        this.$modal.hide("update-submission-modal");
        this.$snotify.success("Submission date updated successfully");
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async addLockExpDetails() {
    try {
      if (this.lockExp.status == "Update" && this.lockExp.note == "") {
        this.error = true;
      } else {
        let res = await Axios.post(
          BASE_API_URL + "processor/dashboard/addLockExpDetails",
          {
            loanId: this.loanId,
            ...this.lockExp,
          },
          {
            headers: authHeader(),
          }
        );
        if ((res.status = 201)) {
          this.$modal.hide("lockExp");
          this.$snotify.success("Lock Expiration Details Added Successfully .");
          this.error = false;
          await this.getAllLoanList(true);
          this.lockDateStatus = true;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async addCloseingDateDetails() {
    try {
      if (this.closingDate.status == "Update" && this.closingDate.note == "") {
        this.error = true;
      } else {
        let res = await Axios.post(
          BASE_API_URL + "processor/dashboard/addClosingDateDetails",
          {
            loanId: this.loanId,
            ...this.closingDate,
          },
          {
            headers: authHeader(),
          }
        );
        if ((res.status = 201)) {
          this.$modal.hide("closeingdate");
          this.$snotify.success("Closing Date Details Added Successfully .");
          this.error = false;
          await this.getAllLoanList(true);
          this.closingDateStatus = true;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async changeLoanStatus() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "processor/dashboard/changeLoanStatus",
        { status: 2, loanId: this.loanId },
        {
          headers: authHeader(),
        }
      );
      if (response.status == 201) {
        await this.getAllLoanList(true);
        this.$snotify.success(response.data.message);
        this.$modal.hide("update-loan-status-modal");
      }
    } catch (error) {
      console.log(error);
    }
  }
  public searchList() {
    try {
      if (this.searchParam.trim() != "" && this.searchKeyword.trim() != "") {
        this.getAllLoanList(true);
      } else {
        this.searchParam.trim() == ""
          ? this.$snotify.error("Select a parameter to search")
          : this.searchKeyword.trim() == ""
          ? this.$snotify.error("Write something before search")
          : "";
      }
    } catch (error) {
      console.log(error);
    }
  }

  public resetList() {
    try {
      this.searchParam = "";
      this.searchKeyword = "";
      this.statusParam = "inProcess";
      this.getAllLoanList(true);
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    await this.getAllLoanList(true);
  }
  /* eslint-disable */
}
